import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { usePrevious } from '../../../../common/custom';
import { userLoginStatus } from '../../../../duck/dashboard/dashboard.action';
import _ from "lodash";
import { getUserDetails, setUserDetails } from '../../../../storage/user';

import WelcomeContactsAndLeadsImg from '../../../../assets/images/WelcomeContactsAndLeads.png'
import QuotesContracts from '../../../../assets/images/QuotesContracts.png'
import InvoicesExpenses from '../../../../assets/images/InvoicesExpenses .png'
import CalendarEmailIntegration from '../../../../assets/images/CalendarEmailIntegration .png'


export const NewWelcomePage = props => {

    const dispatch = useDispatch();
    const loginStatusData = useSelector(state => state.dashboard.loginStatusData);
    const prevLoginStatusData = usePrevious({ loginStatusData });

    const [currentIndex, setCurrentIndex] = useState(0);

    const closedWelcomeModal = () => {
        props.loader(true)
        dispatch(userLoginStatus())
    }

    // List Business Snapshot Data 
    useEffect(() => {
        if (prevLoginStatusData && prevLoginStatusData.loginStatusData !== loginStatusData) {
            if (loginStatusData && _.has(loginStatusData, 'data') && loginStatusData.success === true) {
                let userData = getUserDetails()
                userData.welcome = 1
                setUserDetails(userData)
                props.loader(false)
                props.closeWelcomeModal()
            }
            if (loginStatusData && _.has(loginStatusData, 'message') && loginStatusData.success === false) {
                props.loader(false)
            }
        }

    }, [prevLoginStatusData, loginStatusData]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => {
            if (prevIndex < 4) {
                return prevIndex + 1;
            } else {
                return 4;
            }
        });
    };

    const handlePaginationClick = (index) => {
        setCurrentIndex(index);
    };

    const handlePreModalPrevious = () => {
        setCurrentIndex(3);
    };

    const handlePreModalClose = () => {
        props.closeWelcomeModal();
        props.loader(true)
        dispatch(userLoginStatus())
    };

    const renderContent = () => {
        switch (currentIndex) {
            case 0:
                return (
                    <>
                        <div className="col-lg-6">
                            <img src={WelcomeContactsAndLeadsImg} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="contacts-leads-title">Contacts & Leads</h2>
                            <div className="contacts-leads-points">
                                <ul>
                                    <li>Manage contacts, prospects & leads</li>
                                    <li>Import contacts to create one repository</li>
                                    <li>Customize view to match your process</li>
                                    <li>Drag & drop leads to change stages</li>
                                    <li>Manage appointments, tasks & notes</li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            case 1:
                return (
                    <>
                        <div className="col-lg-6">
                            <img src={QuotesContracts} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="contacts-leads-title">Quotes & Contracts</h2>
                            <div className="contacts-leads-points">
                                <ul>
                                    <li>Add services & create packages</li>
                                    <li>Include your business brand</li>
                                    <li>Create custom templates</li>
                                    <li>Send digital quotes</li>
                                    <li>Track real time progress</li>
                                    <li>Collect e-signatures</li>
                                    <li>Download PDFs</li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="col-lg-6">
                            <img src={InvoicesExpenses} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="contacts-leads-title">Invoices & Expenses</h2>
                            <div className="contacts-leads-points">
                                <ul>
                                    <li>Add services/packages & define terms</li>
                                    <li>Create custom templates</li>
                                    <li>Send digital invoices</li>
                                    <li>Collect payments/advances online</li>
                                    <li>Track payments & expenses</li>
                                    <li>Download expense reports</li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="col-lg-6">
                            <img src={CalendarEmailIntegration} className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="contacts-leads-title">Calendar & Email Integration</h2>
                            <div className="contacts-leads-points">
                                <ul>
                                    <li>Manage bookings & appointments</li>
                                    <li>Access Gmail & M365 email inbox</li>
                                    <li>Integration with Google calendar</li>
                                    <li>Attach files & track payments by bookings</li>
                                </ul>
                            </div>
                        </div>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 mb-5 mt-2">
                                <div className="welcome-points">
                                    <span>1</span>
                                    <p>Use <strong>sample data</strong> to explore and understand key features</p>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5 mt-2">
                                <div className="welcome-points">
                                    <span>2</span>
                                    <p>Complete the steps outlined in <strong>Tasks section</strong> on the dashboard</p>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-5 mt-2">
                                <div className="welcome-points">
                                    <span>3</span>
                                    <p><strong>Delete</strong> the sample data and tasks when ready</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-1 mb-5"></div>
                            <div className="col-lg-10 mb-5">
                                <div className="welcomeVideo">
                                    <iframe width="100%" height="300" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                                </div>
                            </div>
                            <div className="col-lg-1 mb-5"></div> */}
                            <h6>Learn more with our FAQs & tutorials in <a href="https://mybizzhive.com/help/" target="_blank">Help Center</a> anytime.</h6>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {/* Welcome screen Modal props.welcomeModal */}
            <Modal show={props.welcomeModal} onHide={() => closedWelcomeModal()} id="welcome-screen" size="lg">
                <Modal.Body className="text-center d-flex justify-content-between flex-column" style={{ gap: "25px" }}>
                    <div className="modal-header p-0">
                        <button type="button" className="close welcomeClosebtn" onClick={() => closedWelcomeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <h1>Welcome to MyBizz<span>Hive</span></h1>
                    <h4>All-in-one solution to manage your business online</h4>
                    <div className="d-flex justify-content-between flex-column screen-modal-content" style={{ gap: "35px" }}>
                        {renderContent()}
                    </div>
                    <div>
                        {currentIndex < 4 && (
                            <div className="d-flex justify-content-between align-items-center">
                                <button type="button" className={`btn btn-primary previous-btn ${currentIndex > 0 ? '' : 'opacityHide'}`} onClick={handlePrevious}>
                                    Previous
                                </button>
                                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal position-static">
                                    {[...Array(5)].map((_, index) => (
                                        <span
                                            key={index}
                                            className={`swiper-pagination-bullet ${currentIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                                            role="button"
                                            onClick={() => handlePaginationClick(index)}
                                        ></span>
                                    ))}
                                </div>
                                <div className="d-flex justify-content-end align-content-center">
                                    <button type="button" class="btn btn-primary next-btn" onClick={handleNext}>Next</button>
                                </div>
                            </div>
                        )}
                        {currentIndex == 4 && (
                            <div className="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-primary previous-btn" onClick={handlePreModalPrevious}>Previous</button>
                                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal position-static">
                                    {[...Array(5)].map((_, index) => (
                                        <span
                                            key={index}
                                            className={`swiper-pagination-bullet ${currentIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                                            role="button"
                                            onClick={() => handlePaginationClick(index)}
                                        ></span>
                                    ))}
                                </div>
                                <button type="button" class="btn btn-primary next-btn" onClick={handlePreModalClose} style={{ minWidth: "105px" }}>Close</button>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <div>
                    <svg className="wlcm-svg2" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 801 247" style={{ "enableBackground": "new 0 0 801 247" }} xmlSpace="preserve">
                        <path fill="#D8F3F3" d="M0,6.5C106.7,8.9,213.3,22.1,320,46c160.3,36,320.7,96.4,481,88.8l0,112.1c-160.3,0-320.7,0-481,0
                    c-106.7,0-213.3,0-320,0L0,6.5z"/>
                    </svg>
                </div>
            </Modal>
        </>
    );
}

export const Welcome = withRouter(NewWelcomePage)
