import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../../common/custom';
import { PaginationFooter } from '../../../../common/paginationFooter'
import { Header } from '../../../component/frontend/header/header'
import { Footer } from '../../../component/frontend/footer/footer'
import { ADD_BOOKING, VIEW_BOOKING_BASE, VIEW_QUOTE_BASE, VIEW_QUOTE_DETAIL_BASE, VIEW_INVOICE_DETAIL_BASE, VIEW_INVOICE_BASE, VIEW_CONTRACTS_DETAIL_BASE, VIEW_CONTRACTS_BASE, EMAIL_AUTH } from "../../../../routing/routeContants";
import { bookingListView, createBookingPayment, deleteAppointment, getBookingById, isFrombookingList } from '../../../../duck/booking/booking.action';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { constants, isTokenExpired, selectStyle } from "../../../../common/constants";
import Swal from 'sweetalert2'
import { Loader } from '../../../component/frontend/loader/loader'
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from 'moment-timezone/moment-timezone';
import { errorPopUp } from '../../../../common/notification-alert';
import { CreateAppointment } from './createAppointment'
import { CalenderView } from "./calender";
import { BookingPayment } from "./bookingPaymentsModal";
import { validateInputs } from "../../../../common/validation";
import { LIST_BOOKINGS } from "../../../../routing/routeContants";
import { SubscriptionPlan } from "../profile/subscriptionPlans"
import { getUserDetails } from '../../../../storage/user';
import { getCalendarListData, deleteCalendarListData, editCalendarListData, resetCalendarState, deleteBatchCalendarListData } from "../../../../duck/calendar/calendar.action";
import { ViewGoogleEvent } from './viewGoogleEvent'
import { EditGoogleEvent } from './editGoogleEvent'
import { getUserSocialDetails } from "../../../../routing/authService";
import {
    getGmailValidToken, saveSocialAuthToken
} from "../../../../duck/email/email.action";

export const ListBookings = (props) => {
    let bookingId;
    const dispatch = useDispatch();
    const [fetchList, setfetchList] = useState(true);
    const bookingListViewData = useSelector(state => state.booking.bookingListViewData);
    const deleteAppointmentData = useSelector(state => state.booking.deleteAppointmentData);
    const prevbookingListViewData = usePrevious({ bookingListViewData });
    const prevDeleteAppointmentData = usePrevious({ deleteAppointmentData });
    const createBookingPaymentData = useSelector(state => state.booking.createBookingPaymentData);
    const prevCreateBookingPaymentData = usePrevious({ createBookingPaymentData });
    const getBookingByIdData = useSelector(state => state.booking.getBookingByIdData);
    const prevGetBookingByIdData = usePrevious({ getBookingByIdData });
    const getCalendarEventListData = useSelector(state => state.calendar.getCalendarEventListData)
    const prevgetCalendarEventListData = usePrevious({ getCalendarEventListData });
    const deleteCalendarEventData = useSelector(state => state.calendar.getDeleteCalendarEventListData)
    const deleteBatchCalendarEventData = useSelector(state => state.calendar.getDeleteBatchCalendarEventListData)
    const prevDeleteCalendarEventData = usePrevious({ deleteCalendarEventData });
    const editCalendarEventData = useSelector(state => state.calendar.editCalendarEventData)
    const prevEditCalendarEventData = usePrevious({ editCalendarEventData });
    const { tokenErrorData } = useSelector(state => state.calendar);
    const {
        errorTokenData, getGmailAuthTokenData, getGmailTokenData
    } = useSelector((state) => state.email);
    // Appointment Module
    const [appointmentModalShow, setAppointmentModalShow] = useState(false)
    const [googleEventModalShow, setGoogleEventModalShow] = useState(false)
    const [editGoogleEventModalShow, setEditGoogleEventModalShow] = useState(false)
    const userData = getUserDetails();
    const currentPlan = userData && userData.planData
    const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);

    // Set initial State Value  
    const [page, setPage] = useState(1);
    const [limit] = useState(constants.PAGE_LIMIT);
    const [search, setSearch] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [loader, setLoader] = useState(false);
    const [sortingOrder, setSortingOrder] = useState('ASC');
    const [sortingField, setSortingField] = useState('start_date');
    const [allCheckedValue, setAllCheckedValue] = useState([]);
    let [bookingList, setBookingList] = useState([]);
    const [calenderListView, setCalenderListView] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [listView, setListView] = useState("list-view");
    const [appointmentData, setAppointmentData] = useState();
    const [appointmentStatus, setAppointmentStatus] = useState(false)
    const [childComponentName, setChildComponentName] = useState("")
    let [eventListData, setEventListData] = useState([])
    let [editGoogleEventData, setEditGoogleEventData] = useState([])
    const [showEventType, setShowEventType] = useState('MyBizzHive')
    const [googleDataList, setGoogleDataList] = useState([])
    const [filteredGoogleDataList, setFilteredGoogleDataList] = useState([])

    const userSocialData = getUserSocialDetails();
    const accessToken = (userSocialData?.google?.access_token && !isTokenExpired(userSocialData?.google?.expire_in));
    const socialUserEmail = (userSocialData?.google?.social_email)

    const searchTypeOptions = [{ value: "Upcoming", label: 'Upcoming' }, { value: "Completed", label: 'Completed' }, { value: "All", label: 'All' }];
    const [state, setState] = useState({
        searchOptions: { value: "Upcoming", label: "Upcoming" },
        totalAmount: 0, date: '', dateErr: '', dateCls: '', amountErr: '', amountCls: ''

    });
    const [name, setName] = useState('');
    const getBookingListData = useCallback(() => {
        if (childComponentName === "calendar") {
            dispatch(bookingListView())
        } else {
            console.log(state.searchOptions.value,"else")
            if (state.searchOptions && state.searchOptions.value === 'Upcoming') {
                dispatch(bookingListView({ page, future_booking: 1, limit, filter: search, sortingOrder, sortingField,  }))
            } else if (state.searchOptions && state.searchOptions.value === 'Completed') {
                dispatch(bookingListView({ page, future_booking: 0, limit, filter: search, sortingOrder, sortingField, }))
            } else {
                dispatch(bookingListView({ page, limit, filter: search, sortingOrder, sortingField, }))
            }
            // dispatch(bookingListView({ page, limit, sortingField: "start_date", sortingOrder: "ASC", future_booking: 1 }))
        }
    }, [state.searchOptions.value])
    const getModalShowStatus = (status, data, appointment, name) => {
        setAppointmentModalShow(status);
        setAppointmentData(data);
        setAppointmentStatus(appointment)
        setChildComponentName(name)
    }

    const getGoogleEventModalShowStatus = (status, data, appointment, name) => {
        setGoogleEventModalShow(status);
        setAppointmentData(data);
        setAppointmentStatus(appointment)
        setChildComponentName(name)
    }

    // On Load Get Booking
    useEffect(() => {
        setLoader(true)
        if((showEventType ==="Google" && !accessToken)){
            dispatch(isFrombookingList(true))
            props.history.push(EMAIL_AUTH);
        }else if ((deleteBatchCalendarEventData && _.has(deleteBatchCalendarEventData, "success") && deleteBatchCalendarEventData.success === "complete") || (showEventType ==="Google" && accessToken)) {
            dispatch(getCalendarListData(socialUserEmail))
        }
        else{
            getBookingListData();
        }
    }, [showEventType,accessToken,deleteBatchCalendarEventData]); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (
    //         (((errorTokenData && Object.keys(errorTokenData).length === 0) || (tokenErrorData && Object.keys(tokenErrorData).length === 0)) && (((getGmailAuthTokenData && getGmailAuthTokenData?.access_token) || (getGmailTokenData && Object.keys(getGmailTokenData).length > 0)) ||
    //             (userSocialData &&
    //                 _.has(userSocialData, "google") &&
    //                 Object.keys(userSocialData.google).length > 0 &&
    //                 userSocialData.google.access_token)))
    //     ) {
    //         setListView("calender-view");
    //         dispatch(getCalendarListData(socialUserEmail))
    //         const updateGmailAuthTokenData = {
    //             loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in, 
    //             social_email : getGmailTokenData?.social_email,
    //             social_user_id : getGmailTokenData?.social_user_id,
    //             social_user_name : getGmailTokenData?.social_user_name,
    //           }
    //           const updateLocalTokenData = {
    //             loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
    //             social_email : userSocialData?.google?.social_email,
    //             social_user_id : userSocialData?.google?.social_user_id,
    //             social_user_name : userSocialData?.google?.social_user_name,
    //           }
    //         const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
    //         dispatch(saveSocialAuthToken(updatedData));
    //     }
    // }, [errorTokenData, getGmailAuthTokenData, tokenErrorData])

    // useEffect(() => {
    //     if (
    //         userSocialData &&
    //         _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
    //         && ((errorTokenData && Object.keys(errorTokenData).length > 0) || (tokenErrorData && Object.keys(tokenErrorData).length > 0))
    //     ) {
    //         if ((errorTokenData.code === 401) || (tokenErrorData.code === 401)) {
    //             const refreshToken = userSocialData?.google?.refresh_token
    //             dispatch(getGmailValidToken(refreshToken));
    //             dispatch(resetCalendarState());
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, errorTokenData, tokenErrorData])

    // List Booking Data
    useEffect(() => {
        if (prevbookingListViewData && prevbookingListViewData.bookingListViewData !== bookingListViewData) {

            if (bookingListViewData && _.has(bookingListViewData, 'data') && bookingListViewData.success === true) {
                const totalEventsRecords = eventListData.length > 0 ? bookingListViewData.total + eventListData.length : bookingListViewData.total;
                setLoader(false)
                setBookingList(bookingListViewData.data)
                setTotalRecords(totalEventsRecords)
                setfetchList(false);
                setGetBookingByIdpaymentsData(bookingListViewData.data);
            }
            if (bookingListViewData && _.has(bookingListViewData, 'message') && bookingListViewData.success === false) {
                setLoader(false)
                setfetchList(false);
                errorPopUp(bookingListViewData.message)
            }
        }
    }, [bookingListViewData, prevbookingListViewData]);

    // List Events data
    useEffect(() => {
        if (getCalendarEventListData?.items?.length !== undefined && getCalendarEventListData?.items?.length > 0 && prevgetCalendarEventListData && prevgetCalendarEventListData.getCalendarEventListData !== getCalendarEventListData) {
            let gmailEventData = (getCalendarEventListData?.items).map((item) => {
                return {
                    key: 'googleEvent',
                    amount: 0,
                    booking_invoice: '',
                    booking_invoice_payment_total: 0,
                    booking_payments: 0,
                    contact_id: '',
                    created_at: item?.created,
                    created_by: item?.creator?.displayName,
                    detail: item?.description,
                    duration: '',
                    email: item?.creator?.email,
                    end_date: item?.end?.dateTime,
                    event_type: item?.eventType,
                    first_name: item?.creator?.displayName?.split(" ")[0],
                    id: item?.id,
                    invoice: '',
                    invoice_serial_nos: '',
                    last_name: item?.creator?.displayName?.split(" ")[1],
                    lat_long: '',
                    lead: '',
                    lead_id: '',
                    location: item?.location,
                    meeting_id: '',
                    more_information: '',
                    name: item?.summary,
                    organization: '',
                    passcode: '',
                    phone: '',
                    phone_type: '',
                    quote_id: '',
                    quote_serial_no: '',
                    quote_status_type_id: '',
                    received_amount: 0,
                    referred_by: '',
                    service_type_id: '',
                    service_type_name: '',
                    source_type_id: '',
                    start_date: item?.start?.dateTime,
                    title: item?.summary,
                    type: 'googleEvents',
                    virtual_event: '',
                    website: '',
                    attendees: item?.attendees,
                    reminder: item?.reminders
                }
            })

            if (getCalendarEventListData) {
                if(showEventType==="Google"){
                    filterEventData(state.searchOptions.value,gmailEventData)
                    setGoogleDataList(gmailEventData)
                    setLoader(false)
                    // setEventListData(gmailEventData)
                }else{
                    setEventListData(gmailEventData)
                }
            }
        }
    }, [getCalendarEventListData, prevgetCalendarEventListData]);

    useEffect(() => {
        if (listView === "list-view"){
            setEventListData([]);
        }else if (listView === "calendar-view"){
            getBookingListData()
        }
    }, [listView])

    useEffect(() => {
        if (prevDeleteCalendarEventData && prevDeleteCalendarEventData.deleteCalendarEventData !== deleteCalendarEventData) {
            if (deleteCalendarEventData && _.has(deleteCalendarEventData, "success") && deleteCalendarEventData.success === "complete") {
                setGoogleEventModalShow(!googleEventModalShow);
            }
        }
    }, [deleteCalendarEventData, prevDeleteCalendarEventData]);


    useEffect(() => {
        if (prevEditCalendarEventData && prevEditCalendarEventData.editCalendarEventData !== editCalendarEventData) {
            if (editCalendarEventData && _.has(editCalendarEventData, "success") && editCalendarEventData.success === "complete") {
                setEditGoogleEventModalShow(!editGoogleEventModalShow);
            }
        }
    }, [editCalendarEventData, prevEditCalendarEventData]);

    const handleEventDelete = (eventId) => {
        let data = {
            socialUserEmail: socialUserEmail,
            eventId: eventId
        }
        dispatch(deleteCalendarListData(data))
    }

    const handleEventEdit = (EventData) => {
        setEditGoogleEventModalShow(true)
        setEditGoogleEventData(EventData)
        if (!editGoogleEventModalShow) {
            setGoogleEventModalShow(false)
        }
    }
    const handleSubmit = (e, data, setState, contactState) => {
        e.preventDefault()

        let dateValueUniErr = '', dateValueUniCls = '';
        let toValueUniErr = '', toValueUniCls = '';
        let error = contactState.wrongInput;
        let fromValueUni = data.start
        let toValueUni = data.end
        let getError = false;

        // start date
        let start_time = moment(fromValueUni).format("HH:mm:ss");
        let end_time = moment(toValueUni).format("HH:mm:ss");
        // end date
        let current_date = moment(fromValueUni).format("YYYY-MM-DD");
        let end_date = moment(toValueUni).format("YYYY-MM-DD");

        if (current_date > end_date) {
            dateValueUniErr = 'End date should be greater than start date';
            dateValueUniCls = error
            getError = true;
        }

        if (start_time >= end_time) {
            toValueUniErr = 'End time should be greater than start time';
            toValueUniCls = error
            getError = true;
        }

        setState({
            ...data, toValueUniErr, toValueUniCls, dateValueUniErr, dateValueUniCls
        })

        if (!dateValueUniErr && !toValueUniErr) {
            setLoader(true)
            dispatch(editCalendarListData(data))
        }
    }

    // Data Get By Pagination
    const getPageData = (page) => {
        setPage(page);
        setLoader(true)
        setAllCheckedValue([]);
        setCheckedAll(false);
        if(showEventType==="Google"){
            let data =filteredGoogleDataList.slice((page-1) * 10,page * 10);
            setBookingList(data)
            setLoader(false)
        }else{
            if (state.searchOptions && state.searchOptions.value === 'Upcoming') {
                dispatch(bookingListView({ page, future_booking: 1, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
            } else if (state.searchOptions && state.searchOptions.value === 'Completed') {
                dispatch(bookingListView({ page, future_booking: 0, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
            } else {
                dispatch(bookingListView({ page, limit, filter: search, sortingOrder, sortingField, searchField: "first_name,last_name,email,organization" }))
            }
        }
    }

    //Data Get By Search
    const onSearchResult = (search) => {
        if (search.label === "Upcoming") {
            search.value = ""
        }
        /* if (typingTimeout) {
            clearTimeout(typingTimeout);
        } */
        setPage(1);
        setSearch(search);
        setAllCheckedValue([]);
        setCheckedAll(false);
        if(showEventType==="Google"){
            filterEventData(search.label,googleDataList)
        }else{
            if (search.label === "Upcoming") {
                setState({ searchOptions: { value: 'Upcoming', label: 'Upcoming' } })
                setSortingField('start_date');
                setSortingOrder('ASC');
    
                dispatch(bookingListView({ future_booking: 1, page, limit, sortingField: "start_date", sortingOrder: "ASC" }))
            } else if (search.label === "Completed") {
                setState({ searchOptions: { value: 'Completed', label: 'Completed' } })
                setSortingField('start_date');
                setSortingOrder('DESC');
    
                dispatch(bookingListView({ page, limit, future_booking: 0, sortingField: "start_date", sortingOrder: "DESC" }))
            }
            else if (search.label === "All") {
                setState({ searchOptions: { value: 'All', label: 'All' } })
                setSortingField('start_date');
                setSortingOrder('ASC');
                /*  setTypingTimeout(setTimeout(function () {
                     dispatch(listBookings({ page, limit, sortingField: "start_date", sortingOrder: "ASC" }))
                 }, 300)) */
                dispatch(bookingListView({ page, limit, sortingField: "start_date", sortingOrder: "ASC" }))
            }
        }
    }

    const filterEventData = (filterType,googleDataList) => {
        const today = new Date();
        let result = [];
        switch (filterType) {
          case 'Upcoming':
            result = googleDataList.filter(item => new Date(item.end_date) > today);
            setState({ searchOptions: { value: 'Upcoming', label: 'Upcoming' } })
            setTotalRecords(result.length)
            break;
          case 'Completed':
            result = googleDataList.filter(item => new Date(item.end_date) <= today);
            setState({ searchOptions: { value: 'Completed', label: 'Completed' } })
            setTotalRecords(result.length)
            break;
          case 'All':
          default:
            result = googleDataList;
            setState({ searchOptions: { value: 'All', label: 'All' } })
            setTotalRecords(result.length)
            break;
        }
        let data =result.slice((page-1) * 10,page * 10)
        setFilteredGoogleDataList(result)
        setBookingList(data);
      };    

    // On change select checkbox value
    const onCheckedValue = (obj) => {
        let newArr;
        if (_.some(allCheckedValue, ['id', obj.id])) {
            newArr = _.filter(allCheckedValue, (data) => data.id !== obj.id)
        } else {
            newArr = [...allCheckedValue, obj]
        }
        if (newArr.length === bookingList.length) {
            setCheckedAll(true)
            setAllCheckedValue(newArr)
        } else {
            setCheckedAll(false)
            setAllCheckedValue(newArr)
        }
    }

    // Delete Booking Data
    const deleteContactFunction = (e) => {
        e.preventDefault();
        if (allCheckedValue.length === 0) {
            Swal.fire({
                text: "Please select at least one booking or appointment to delete.",
                showConfirmButton: false,
                showCancelButton: true,
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
                cancelButtonText: 'Ok',
            }).then((result) => {

            })
        } else {
            let msg = 'If you delete selected Booking(s) or Appointment(s), all associated Notes, Tasks and references will be lost. Are you sure you want to proceed?';
            if (allCheckedValue.length > 1) {
                msg = 'If you delete selected Booking(s) or Appointment(s), all associated Notes, Tasks and references will be lost. Are you sure you want to proceed?';
            }
            Swal.fire({
                title: 'Are you sure?',
                text: msg,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'No, keep it',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setLoader(true);
                    if(showEventType==="MyBizzHive"){
                        dispatch(deleteAppointment({ ids: JSON.stringify(allCheckedValue) }))
                    }else if(showEventType ==="Google" && accessToken){
                         let data = {
                            socialUserEmail: socialUserEmail,
                            eventIds: allCheckedValue,
                            token: userSocialData?.google?.access_token
                        }
                        dispatch(deleteBatchCalendarListData(data))
                    }else{
                        dispatch(isFrombookingList(true))
                        props.history.push(EMAIL_AUTH);
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                }
            })
        }
    }

    const handleLinkClick = (data) => {
        setAppointmentStatus(false)
        setAppointmentData(data)
        setAppointmentModalShow(true)
        //setGoogleEventModalShow(true)
    }

    // Get Delete Booking Data Props
    useEffect(() => {
        if (prevDeleteAppointmentData && prevDeleteAppointmentData.deleteAppointmentData !== deleteAppointmentData) {
            if (deleteAppointmentData && _.has(deleteAppointmentData, 'data') && deleteAppointmentData.success === true) {
                setAllCheckedValue([]);
                if (checkedAll === true) {
                    setCheckedAll(false);
                    setPage(1)
                    if (state.searchOptions && state.searchOptions.value === 'Upcoming') {
                        dispatch(bookingListView({ page, future_booking: 1, limit, filter: search, sortingOrder, sortingField }))
                    } else if (state.searchOptions && state.searchOptions.value === 'Completed') {
                        dispatch(bookingListView({ page, future_booking: 0, limit, filter: search, sortingOrder, sortingField }))
                    } else {
                        dispatch(bookingListView({ page, limit, filter: search, sortingOrder, sortingField }))
                    }
                } else {
                    setCheckedAll(false);
                    if (state.searchOptions && state.searchOptions.value === 'Upcoming') {
                        dispatch(bookingListView({ page, future_booking: 1, limit, filter: search, sortingOrder, sortingField }))
                    } else if (state.searchOptions && state.searchOptions.value === 'Completed') {
                        dispatch(bookingListView({ page, future_booking: 0, limit, filter: search, sortingOrder, sortingField }))
                    } else {
                        dispatch(bookingListView({ page, limit, filter: search, sortingOrder, sortingField }))
                    }
                }
            }
            if (deleteAppointmentData && _.has(deleteAppointmentData, 'message') && deleteAppointmentData.success === false) {
                setLoader(false)
                errorPopUp(deleteAppointmentData.message)
            }
        }
    }, [deleteAppointmentData, prevDeleteAppointmentData]);// eslint-disable-line react-hooks/exhaustive-deps

    // Sort The Booking List 
    const sortTheData = (e, field, orderValue) => {
        let order = orderValue === 'DESC' ? 'ASC' : (orderValue === 'ASC' ? "DESC" : 'DESC');
        e.preventDefault();
        setPage(1);
        setSortingField(field);
        setSortingOrder(order);
        setLoader(true)
        setAllCheckedValue([])
        setCheckedAll(false)
        if ((state.searchOptions && state.searchOptions[0] && state.searchOptions[0].label) || (state.searchOptions.label) === "Upcoming") {
            dispatch(bookingListView({ page: 1, limit, future_booking: 1, filter: search, sortingField: field, sortingOrder: order, searchField: "first_name,start_date,location,amount" }))
        }
        else if (state.searchOptions && state.searchOptions && state.searchOptions.label === "Completed") {
            dispatch(bookingListView({ page: 1, future_booking: 0, limit, filter: search, sortingField: field, sortingOrder: order, searchField: "first_name,start_date,location,amount" }))
        }
        else if (state.searchOptions && state.searchOptions && state.searchOptions.label === "All") {
            dispatch(bookingListView({ page: 1, limit, filter: search, sortingField: field, sortingOrder: order, searchField: "first_name,start_date,location,amount" }))
        }
    }

    const checkedAllCheckbox = (data) => {
        if (data) {
            let allCheck = _.map(bookingList, (data) => {
                let obj;
                return (
                    obj = { id: data.id, type: data.type == "booking" ? "b" : "a" }
                )
            });
            setAllCheckedValue(allCheck)
        } else {
            setAllCheckedValue([])
        }
        setCheckedAll(data)
    }
    const [getBookingByIdpaymentsData, setGetBookingByIdpaymentsData] = useState([]);
    const [getBookingPaymentStatus, setgetBookingPaymentStatus] = useState(false)

    const PaymentMethodTypeOption = [{ value: 'Online payment', label: 'Online payment' }, { value: 'Credit Card ', label: 'Credit Card ' }, { value: 'Cash', label: 'Cash' }, { value: 'Bank Check', label: 'Bank Check' }, { value: 'Square ', label: 'Square ' }, { value: 'Stripe', label: 'Stripe' }, { value: 'Venmo', label: 'Venmo' }, { value: 'PayPal', label: 'PayPal' }];
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [bookingPaymentsServiceMessage, setBookingPaymentsServiceMessage] = useState('');
    const [datePayment, setDatePayment] = useState({
        dateCls: '', dateErr: '', totalCls: '', totalErr: '', amountErr: '', amountCls: ''
    })
    //Booking Payment State
    const [paymentItems, setPaymentItems] = useState([
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: '' },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: '' },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: '' },
        { payment_date: '', payment_method: { value: 'Online payment', label: 'Online payment' }, payment_amount: '' }
    ]);
    const [bookingIdValue, setBookingIdValue] = useState('')
    const [received_amount, setReceived_amount] = useState('')

    const BookingPaymentModal = (id, received_amount, data) => {
        setName(data.name);
        bookingId = id;
        setReceived_amount(received_amount);
        setBookingIdValue(id);
        //dispatch(getBookingById({ id: bookingId }))
        let arr = [];
        if (getBookingByIdpaymentsData) {
            _.map(getBookingByIdpaymentsData, (val, ind) => {
                if (val.id == bookingId && val.booking_payments.length > 0) {
                    setgetBookingPaymentStatus(true);
                    _.map(val.booking_payments, (val, ind) => {
                        arr.push({ payment_date: val.payment_date ? moment(val.payment_date).toDate() : '', payment_method: val.payment_method ? { value: val.payment_method, label: val.payment_method } : { value: 'Online payment', label: 'Online payment' }, payment_amount: val.payment_amount ? val.payment_amount : '' })
                    })
                    for (let i = 1; i => 4; i++) {
                        if (arr.length < 4) {
                            arr.push({ payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' })
                        }
                        else {
                            break;
                        }
                    }
                    setPaymentItems(arr)
                }
                if (val.id == bookingId && val.booking_payments.length == 0) {
                    setPaymentItems([
                        { payment_date: data.created_at ? moment(data.created_at).toDate() : '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: data.received_amount ? data.received_amount : '' },
                        { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                        { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                        { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' }
                    ]);
                }
            })

        } else {
            setPaymentItems([
                { payment_date: data.created_at ? moment(data.created_at).toDate() : '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: data.received_amount ? data.received_amount : 0 },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' },
                { payment_date: '', payment_method: { value: 'Online Payment', label: 'Online Payment' }, payment_amount: '' }
            ]);
        }
        setDatePayment({ dateCls: '', dateErr: '', totalCls: '', totalErr: '', amountErr: '', amountCls: '' })
        setBookingPaymentsServiceMessage('');
        setPaymentModalShow(true);
    }
    const PaymentItemSetData = (i, event, name) => {
        let itm = [...paymentItems];
        itm[i] = { ...itm[i], [name]: event };
        setPaymentItems(itm);
        setBookingPaymentsServiceMessage('');
    }
    // Line Item Value Set
    const PaymentItemSetValue = (i, event) => {
        const { name, value } = event.target;
        let itm = [...paymentItems];
        itm[i] = { ...itm[i], [name]: value };
        setPaymentItems(itm);
    }
    // Line Item Remove
    const removePaymentItem = (e, index) => {
        e.preventDefault();
        const item = [...paymentItems];
        item.splice(index, 1);
        setPaymentItems(item);
    }
    //Calculate Total Amount
    useEffect(() => {
        const calculateTotalAmount = () => {
            const mainFee = _.filter(paymentItems, (dd) => _.has(dd, 'payment_amount'));
            const totalFee = _.sumBy(mainFee, fee => {
                return (parseFloat(fee.payment_amount) ? parseFloat(fee.payment_amount) : 0);
            });
            setState({ ...state, totalAmount: (parseFloat(totalFee)) })
        }
        calculateTotalAmount()
    }, [paymentItems])// eslint-disable-line react-hooks/exhaustive-deps

    const handleBookingPayments = () => {
        if (currentPlan && currentPlan.plan_is_active === 1) {
            let success = '';
            let error = 'error';
            let dateErr = '', dateCls = success, getError = false, totalErr = '',
                amountErr = '', amountCls = success, totalCls = success;
            let paymentarr = [];
            let paymentData;

            _.map(paymentItems, (val, ind) => {
                if (val.payment_amount && val.payment_date) {
                    paymentarr.push(
                        { "payment_date": val.payment_date ? moment(val.payment_date).format('DD-MM-YYYY') : '', "payment_amount": val.payment_amount ? val.payment_amount : 0, "payment_method": val.payment_method.value }
                    )
                }
                if (paymentarr.length == 0) {
                    totalErr = 'At least fill one row of amount data ';
                    totalCls = error;
                    getError = true;
                }

                if (val.payment_amount) {
                    if (validateInputs('required', (val.payment_date)) === 'empty' || val.payment_date === null) {
                        dateErr = 'Please select date ';
                        dateCls = error;
                        getError = true;
                    }
                }
            })

            /* if(received_amount < state.totalAmount ){
                totalErr = 'Total Amount more than deposit amount.';
                totalCls = error;
                getError = true;
            } */

            if (state.totalAmount < 0) {
                totalErr = 'Total Amount is negative.';
                totalCls = error;
                getError = true;
            }


            setDatePayment({
                ...datePayment, dateCls, dateErr, totalErr, amountErr, amountCls
            })
            paymentData = JSON.stringify({ payments: paymentarr })
            setBookingPaymentsServiceMessage('')
            if (getError === false && dateErr === '' && totalErr === '' && amountCls === '') {
                setLoader(true)
                let bookingPaymentData = {
                    booking_id: bookingIdValue,
                    payments: paymentData
                };
                dispatch(createBookingPayment(bookingPaymentData))
            }
        } else {
            Swal.fire({
                title: 'Upgrade Required',
                html: 'Your current plan doesn’t have access to this feature. Please upgrade your account and try again.',
                showCancelButton: true,
                confirmButtonText: 'View Plans',
                cancelButtonText: 'Close',
                reverseButtons: true,
                showCloseButton: true,
                customClass: "mycustom-alert",
                cancelButtonClass: 'cancel-alert-note',
            }).then((result) => {
                if (result.value) {
                    setSubscriptionModalShow(true)
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // console.log('cancel')
                }
            })
        }
    }
    //After Create Booking Payments
    useEffect(() => {
        if (prevCreateBookingPaymentData && prevCreateBookingPaymentData.createBookingPaymentData !== createBookingPaymentData) {
            if (createBookingPaymentData && _.has(createBookingPaymentData, 'data') && createBookingPaymentData.success === true) {
                setPaymentModalShow(false)
                setLoader(false)
                //dispatch(getBookingById({ id: bookingIdValue }))
                getBookingListData();
            }
            if (createBookingPaymentData && _.has(createBookingPaymentData, 'message') && createBookingPaymentData.success === false) {
                //setPaymentModalShow(false)
                setLoader(false)
                setBookingPaymentsServiceMessage(createBookingPaymentData.message)
            }
        }
    }, [createBookingPaymentData, prevCreateBookingPaymentData]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (prevGetBookingByIdData && prevGetBookingByIdData.getBookingByIdData !== getBookingByIdData) {
            if (getBookingByIdData && _.has(getBookingByIdData, 'data') && getBookingByIdData.success === true) {
                setLoader(false)
                if (getBookingByIdData.data && getBookingByIdData.data.id) {
                    if (getBookingByIdData.data.payments.length > 0) {
                        // setgetBookingPaymentStatus(true);
                        setGetBookingByIdpaymentsData(getBookingByIdData.data.payments);
                    }
                } else {
                    props.history.push(LIST_BOOKINGS)
                }
            }
            if (getBookingByIdData && _.has(getBookingByIdData, 'message') && getBookingByIdData.success === false) {
                setLoader(false)
            }
        }
    }, [getBookingByIdData, prevGetBookingByIdData]);// eslint-disable-line react-hooks/exhaustive-deps
    // Email Connection Check For Default Calender View:
    useEffect(() => {
        if (accessToken) {
            // dispatch(bookingListView())
            // dispatch(getCalendarListData(socialUserEmail))
            // setListView("calender-view")
        }
    }, [accessToken])
    return (
        <>
            <Loader loader={loader} />
            <div className="main-site fixed--header ">
                <section id="main-header" >
                    <Header getMainRoute={"bookings"} />
                </section>
                <main className="site-body">
                    <section className="page-title contact--header bookingheader">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-auto title--col">
                                    <div>
                                        <h2 className="title">Bookings</h2>
                                        <p className="m-0">Total <span>{totalRecords}</span></p>
                                    </div>
                                </div>

                                {/* <div className="col-lg-auto title--col">
                                    <div>
                                        <h2 className="title">Calendar ({totalRecords})</h2>
                                    </div>
                                </div>
                                 */}
                                <div className="col-auto ml-auto d-flex align-items-center title-elems">
                                    {/* <span className="d-none d-lg-block">View</span> */}
                                    {listView == "list-view" ?
                                        <>
                                            <div className="d-flex align-items-center mr-15">
                                            <p className="mb-0 mbHide ">Show events of</p>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline3" onChange={() => setShowEventType('MyBizzHive')} checked={showEventType === 'MyBizzHive'} name="showEventsOf" className="custom-control-input" value="1" />
                                                    <label className="custom-control-label" htmlFor="customRadioInline3">MyBizzHive</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline4" onChange={() => setShowEventType('Google')} checked={showEventType === 'Google'} name="showEventsOf" className="custom-control-input" value="0" />
                                                    <label className="custom-control-label" htmlFor="customRadioInline4">Google</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mr-15 mright mobFIx-items">
                                                {/* <p className="mb-0 mr-2 mbHide">View</p> */}
                                                <Select
                                                    className="floating-select cstselectmin-w d-lg-block   ml-15"
                                                    styles={selectStyle}
                                                    isSearchable={false}
                                                    components={makeAnimated()}
                                                    options={searchTypeOptions}
                                                    value={state.searchOptions}
                                                    onChange={(e) => onSearchResult(e)}
                                                /* menuIsOpen={true} */
                                                // onChange={(data) => setState({ ...state, searchOptions: data })}
                                                />
                                            </div>
                                            <button type="button" href="#google" onClick={(e) => deleteContactFunction(e)} className="btn btn-danger  mright mr-15">Delete</button>
                                            <div className="btn-divider mr-15 d-none d-lg-block"></div>
                                        </> : ""}

                                    {/* <input type="text" name="search" value={search} onChange={(e) => onSearchResult(e.target.value)} placeholder="Search Contact" className="form-control d-none d-lg-block" /> */}
                                    <div className="svg-tooltip" data-toggle="tooltip" data-placement="top" title="List view">
                                        <svg data-toggle="tooltip" data-placement="right" title="Tooltip on right" className={listView == "list-view" ? "list-view-icon mr-15 mright active" : "list-view-icon mright mr-15"} width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                            dispatch(bookingListView({ page, limit, sortingField: "start_date", sortingOrder: "ASC", future_booking: 1 }));
                                            setListView("list-view");
                                        }}>
                                            <rect width="31.5" height="3" />
                                            <rect y="9" width="31.5" height="3" />
                                            <rect y="18" width="31.5" height="3" />
                                            <rect y="27" width="31.5" height="3" />
                                        </svg>
                                    </div>
                                    <div className="svg-tooltip" data-toggle="tooltip" data-placement="top" title="Calender view">
                                        <svg className={listView == "calender-view" ? "grid-view-icon mr-15 mright active" : "grid-view-icon mright mr-15"} width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                            setShowEventType('MyBizzHive')
                                            dispatch(bookingListView())
                                            if (accessToken) {
                                                dispatch(getCalendarListData(socialUserEmail))
                                            }
                                            setListView("calender-view")
                                        }}>
                                            <rect width="6.52174" height="6.52174" />
                                            <rect x="13.0435" width="6.52174" height="6.52174" />
                                            <rect x="26.0869" width="6.52174" height="6.52174" />
                                            <rect y="11.7391" width="6.52174" height="6.52174" />
                                            <rect x="13.0435" y="11.7391" width="6.52174" height="6.52174" />
                                            <rect x="26.0869" y="11.7391" width="6.52174" height="6.52174" />
                                            <rect y="23.4783" width="6.52174" height="6.52174" />
                                            <rect x="13.0435" y="23.4783" width="6.52174" height="6.52174" />
                                            <rect x="26.0869" y="23.4783" width="6.52174" height="6.52174" />
                                        </svg>
                                        {listView == "list-view" && 
                                        <a 
                                        className="text-link mr-15 mright"
                                        onClick={() => {
                                        setShowEventType('MyBizzHive')
                                        dispatch(bookingListView())
                                        if (accessToken) {
                                            dispatch(getCalendarListData(socialUserEmail))
                                        }
                                        setListView("calender-view")
                                        }} >Calender View
                                        </a>}
                                        {listView == "calender-view" &&
                                         <a 
                                         className="text-link mr-15 mright" 
                                         onClick={() => {
                                            dispatch(bookingListView({ page, limit, sortingField: "start_date", sortingOrder: "ASC", future_booking: 1 }));
                                            setListView("list-view");
                                        }}>List View
                                        </a>}
                                    </div>
                                    <div className="btn-divider mr-15 mright d-none d-lg-block"></div>
                                    <div className="dropdown custom-dropdown mr-15 mright">
                                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Create
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Link to={ADD_BOOKING} className="dropdown-item">Create Booking </Link>
                                            <a class="dropdown-item" onClick={() => {
                                                setAppointmentStatus(true)
                                                setAppointmentModalShow(true)
                                            }}>Create Appointment</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="middle-section">
                        <div className="container">
                            <div className="row no-gutters-mbl">
                                <div className="col-12">
                                    <div className="main-card">
                                        <div className="card">
                                            <div className="card-body pt-0">
                                                <div className="bookingResp_table table-responsive">
                                                    {listView == "list-view" ? <table className={(bookingList && bookingList.length > 0) ? "table table-lg table-striped contacts--table smart-table" : "table table-lg table-striped contacts--table smart-table empty-booking-table"}>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    {bookingList.length > 0 ?
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" name="check_all" onChange={(e) => checkedAllCheckbox(e.target.checked)} checked={checkedAll} className="custom-control-input" id={'checkAll'} />
                                                                            <label className="custom-control-label" htmlFor={'checkAll'}></label>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </th>
                                                                <th >
                                                                    <div className="table--sorting">
                                                                        Date & Time
                                                                        <div className="sorting-icn">
                                                                            <a href="#desc" className={sortingField === 'start_date' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'start_date', sortingOrder)}>
                                                                                <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                {/* <th scope="col">Customer</th> */}
                                                                <th >
                                                                    <div className="table--sorting">
                                                                        Customer
                                                                        <div className="sorting-icn">
                                                                            <a href="#desc" className={sortingField === 'first_name' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'first_name', sortingOrder)}>
                                                                                <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th scope="col">Location</th>
                                                                {/* <th >
                                                                    <div className="table--sorting">
                                                                        Location
                                                                    <div className="sorting-icn">
                                                                            <a href="#desc" className={sortingField === 'location' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'location', sortingOrder)}>
                                                                                <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </th> */}
                                                                {/* <th scope="col">Balance (Deposit)</th> */}
                                                                <th >
                                                                    <div className="table--sorting">
                                                                        <div className="d-flex">Balance <div className="notbold ml-1"> (Payments)</div></div>
                                                                        <div className="sorting-icn">
                                                                            <a href="#desc" className={sortingField === 'amount' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'amount', sortingOrder)}>
                                                                                <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                                <th scope="col"> Quote/Invoice</th>
                                                                {/* <th >
                                                                    <div className="table--sorting">
                                                                        Quote/Invoice
                                                                    <div className="sorting-icn">
                                                                            <a href="#desc" className={sortingField === 'organization' ? (sortingOrder === 'DESC' ? "active up" : (sortingOrder === 'ASC' ? "active" : "")) : ''} onClick={(e) => sortTheData(e, 'organization', sortingOrder)}>
                                                                                <svg width="14" height="8" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.90008 0.618506L9.39911 0.103126C9.33236 0.0343033 9.25546 0 9.16853 0C9.08181 0 9.00494 0.0343033 8.93819 0.103126L5.00005 4.15463L1.06209 0.103235C0.995301 0.0344116 0.918439 0.000108326 0.831611 0.000108326C0.744747 0.000108326 0.667886 0.0344116 0.601132 0.103235L0.100235 0.61865C0.0333416 0.687329 0 0.766407 0 0.855776C0 0.945073 0.0334469 1.02415 0.100235 1.09283L4.76957 5.89695C4.83633 5.96566 4.91322 6 5.00005 6C5.08688 6 5.16364 5.96566 5.23036 5.89695L9.90008 1.09283C9.96683 1.02411 10 0.945037 10 0.855776C10 0.766407 9.96683 0.687329 9.90008 0.618506Z" />
                                                                                </svg>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </th> */}
                                                                {/* <th scope="col">Related to</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(bookingList && bookingList.length > 0) ?
                                                                _.map(bookingList, (data) => {
                                                                    let latLngUrl = '';
                                                                    if (data.lat_long && data.lat_long !== null) {
                                                                        let placeData = JSON.parse(data.lat_long);
                                                                        latLngUrl = placeData.lat + ',' + placeData.lng + '&query_place_id=' + placeData.place_id
                                                                    }
                                                                    return <tr key={data.id}>
                                                                        <td>
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input type="checkbox" name={data.id} onChange={(e) => onCheckedValue({ id: data.id, type: data.type == "booking" ? "b" : "a" }, e.target.checked)} checked={_.some(allCheckedValue, ['id', data.id])} className="custom-control-input" id={'check' + data.id} />
                                                                                <label className="custom-control-label" htmlFor={'check' + data.id}></label>
                                                                            </div>
                                                                        </td>
                                                                        <td className="booking--date">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="appointment--date-time">
                                                                                    <div className="booking-list-date">{moment(data.start_date).format('ddd, MMM DD YYYY')}</div>
                                                                                    <div className="ml-1">{moment(data.start_date).format("LT") || '-'}</div>
                                                                                    {/* <div className="ml-1">{moment(data.start_date).format("LT") + ' PST' || '-'}</div> */}
                                                                                </div>
                                                                                {data.type == "appointment" ?
                                                                                    <div
                                                                                        class="tool-tip appointment-info-badge"
                                                                                    >
                                                                                        A
                                                                                    </div>
                                                                                    :
                                                                                    ""
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="booking--name">
                                                                            {data.type === "googleEvents" ? 
                                                                            (<Link onClick={(e) =>getGoogleEventModalShowStatus(true, data, false, "calendar")} className="text-link">{data?.name}<div>{data?.first_name ?? ''} {data?.last_name ? ` ${data?.last_name}` : ''}</div></Link>)
                                                                            :
                                                                            data.type === "booking" ? (<Link to={`${VIEW_BOOKING_BASE}${data.id}`} className="text-link">{data?.name}<div>{data?.first_name ?? ''} {data?.last_name ? ` ${data?.last_name}` : ''}</div></Link>) 
                                                                            : (<Link onClick={(e) => handleLinkClick(data)} className="text-link">{data?.name}<div>{data?.first_name ?? ''} {data?.last_name ? ` ${data?.last_name}` : ''}</div></Link>)
                                                                            }
                                                                        </td>
                                                                        {data.virtual_event === 1 ?
                                                                            <td className="booking--location booking_location">
                                                                                <a href={/^https?:\/\//.test(data.location) ? `${data.location}` : `//${data.location}`} rel="noopener noreferrer" target="_blank" className="text-link">{data.location}</a>
                                                                                <p>Meeting Id: {data.meeting_id !== null ? data.meeting_id : 'N/A'} Passcode: {data.passcode !== null ? data.passcode : 'N/A'}</p>

                                                                            </td>
                                                                            :
                                                                            <td className="booking--location booking_location">
                                                                                {data.location || '-'}
                                                                                {latLngUrl !== '' ?
                                                                                    <a href={"https://www.google.com/maps/search/?api=1&query=" + latLngUrl} rel="noopener noreferrer" target="_blank" className="text-link ml-1">(Map)</a>
                                                                                    : <div></div>}
                                                                            </td>
                                                                        }
                                                                        <td className="booking--amount">
                                                                            {data.type === 'appointment' ? '-' : <div><strong>
                                                                                {data.amount !== null ?
                                                                                    data.booking_invoice_payment_total === 0 ? data.received_amount <= data.amount ? userData.planData.country_currency_symbol + (parseFloat(data.amount) - parseFloat(data.received_amount)) : userData.planData.country_currency_symbol + '0'
                                                                                        : data.booking_invoice_payment_total <= data.amount ? userData.planData.country_currency_symbol + (parseFloat(data.amount) - parseFloat(data.booking_invoice_payment_total)) : userData.planData.country_currency_symbol + '0'
                                                                                    : ''}
                                                                            </strong>
                                                                                {' '}<a className="" onClick={() => { BookingPaymentModal(data.id, data.booking_invoice_payment_total, data) }}><strong>{data.booking_invoice_payment_total === 0 ? "(" + userData.planData.country_currency_symbol + data.received_amount + ")" : "(" + userData.planData.country_currency_symbol + data.booking_invoice_payment_total + ")"}</strong></a>
                                                                            </div>}
                                                                        </td>
                                                                        <td className="booking--quote">
                                                                            {data.quote_id &&
                                                                                <>
                                                                                    {data?.quote?.is_contract === 1 ? <Link to={(data.quote_status_type_id === 1 ? VIEW_CONTRACTS_BASE : VIEW_CONTRACTS_DETAIL_BASE) + data.quote_id} className="text-link">Contract ID: {data.quote_serial_no + ', '}</Link>
                                                                                        :
                                                                                        <Link to={(data.quote_status_type_id === 1 ? VIEW_QUOTE_BASE : VIEW_QUOTE_DETAIL_BASE) + data.quote_id} className="text-link">Quote ID: {data.quote_serial_no + ', '}</Link>
                                                                                    }
                                                                                </>
                                                                            }
                                                                            {
                                                                                (data.invoice).length > 0 ?
                                                                                    _.map(data.invoice, (inv, k) => {
                                                                                        return <React.Fragment key={k}><Link to={(inv.invoice_status_type_id !== 1 ? VIEW_INVOICE_DETAIL_BASE : VIEW_INVOICE_BASE) + inv.id} className="text-link" >Invoice ID:{inv.invoice_serial_no}</Link>{(data.invoice).length === k + 1 ? '' : ', '}</React.Fragment>
                                                                                    })
                                                                                    : ''
                                                                            }
                                                                            {/* {data.quote_id === null && (data.invoice).length === 0 ? '-' : ''}
                    {data.quote_id === null ? '-' : ''} */}
                                                                            {
                                                                                (data.booking_invoice).length > 0 ?
                                                                                    _.map(data.booking_invoice, (inv, k) => {
                                                                                        return <React.Fragment key={k}><Link to={(inv.invoice_status_type_id !== 1 ? VIEW_INVOICE_DETAIL_BASE : VIEW_INVOICE_BASE) + inv.id} className="text-link" >Invoice ID:{inv.invoice_serial_no}</Link>{(data.booking_invoice).length === k + 1 ? '' : ', '}</React.Fragment>
                                                                                    })
                                                                                    : ''
                                                                            }
                                                                        </td >
                                                                    </tr >
                                                                })
                                                                :
                                                                fetchList ?
                                                                    ''
                                                                    :
                                                                    <tr>
                                                                        <td colSpan="6" className="bg-white">
                                                                            <div className="no--contacts">
                                                                                <p>
                                                                                    You don't have any upcoming bookings or appointments{" "}
                                                                                </p>
                                                                                <p>
                                                                                    <a
                                                                                        className="underline-text"
                                                                                        target="_blank"
                                                                                        href="https://mybizzhive.com/help/bookings.html"
                                                                                    >
                                                                                        Learn more
                                                                                    </a>&nbsp;
                                                                                    &nbsp; about how to create or manage bookings.
                                                                                </p>
                                                                                <Link to={ADD_BOOKING} className="btn btn-primary mt-5">Create Booking</Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </tbody >
                                                    </table > :
                                                        <div className="calender-list">
                                                            <CalenderView gmailEventListData={eventListData} eventListData={bookingList}
                                                                getEventModalStatusProp={(e, data, appointment, childName) => getGoogleEventModalShowStatus(e, data, appointment, childName)}
                                                                getModalStatusProp={(e, data, appointment, childName) => getModalShowStatus(e, data, appointment, childName)} />
                                                        </div>
                                                    }

                                                </div >
                                                {totalRecords && listView == "list-view" ? <PaginationFooter getPageData={(data) => getPageData(data)} pageNo={page} totalRecords={totalRecords} limit={limit} /> : ''}
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </section >

                </main >
                {/* Subscription Modal*/}
                < SubscriptionPlan loader={(data) => setLoader(data)}
                    openSubscriptionModal={subscriptionModalShow}
                    closeSubscriptionModal={() => setSubscriptionModalShow(false)}
                    updatePlanDetail={(data) => { setSubscriptionModalShow(false); setLoader(false) }}
                    currentPlan={currentPlan}
                />
                <CreateAppointment appointmentModalShow={appointmentModalShow} setAppointmentModalShow={(e) => setAppointmentModalShow(e)} appointmentData={appointmentStatus ? '' : appointmentData} appointmentStatus={appointmentStatus} getNewProp={() => getBookingListData()} setLoader={setLoader} />
                {googleEventModalShow && <ViewGoogleEvent handleEventEdit={handleEventEdit} handleEventDelete={handleEventDelete} googleEventModalShow={googleEventModalShow} setGoogleEventModalShow={(e) => setGoogleEventModalShow(e)} appointmentData={appointmentStatus ? '' : appointmentData} appointmentStatus={appointmentStatus} getNewProp={() => getBookingListData()} setLoader={setLoader} />}
                {editGoogleEventModalShow && <EditGoogleEvent handleSubmit={handleSubmit} editGoogleEventData={editGoogleEventData} editGoogleEventModalShow={editGoogleEventModalShow} setEditGoogleEventModalShow={(e) => setEditGoogleEventModalShow(e)} />}
                <BookingPayment
                    paymentModalShow={paymentModalShow}
                    setPaymentModalShow={() => setPaymentModalShow(false)}
                    name={name}
                    datePayment={datePayment}
                    bookingPaymentsServiceMessage={bookingPaymentsServiceMessage}
                    paymentItems={paymentItems}
                    PaymentItemSetData={(i, event, name) => { PaymentItemSetData(i, event, name) }}
                    PaymentItemSetValue={(i, event) => PaymentItemSetValue(i, event)}
                    handleBookingPayments={() => handleBookingPayments()}
                    totalAmount={state.totalAmount}
                    getBookingPaymentStatus={getBookingPaymentStatus}
                    currentPlan={currentPlan}
                />
                <Footer />
            </div >
        </>
    );
}
