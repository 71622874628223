import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { EmailHeader } from "../../../component/frontend/email/EmailHeader";
import { Footer } from "../../../component/frontend/footer/footer";
import { Header } from "../../../component/frontend/header/header";
import { Loader } from "../../../component/frontend/loader/loader";
import {
  setImagePath, usePrevious,
} from "../../../../common/custom";
import COMPOSE_ICON from "../../../../assets/images/edit_FILL0_wght400_GRAD0_opsz24.svg";

import Swal from "sweetalert2";
import { getUserDetails } from "../../../../storage/user";
import { useDispatch, useSelector } from "react-redux";
import {
  getGmailAuthLink,
  getGmailMessageAttachmentById,
  getGmailMessageById,
  getGmailValidToken,
  markGmailMessageAsTrash,
  markGmailMessageAsUnread,
  modifyGmailMessage,
  saveSocialAuthToken,
} from "../../../../duck/email/google/google.action";
import _ from "lodash";
import {
  ADD_CONTACT,
  ADD_LEAD,
  COMPOSE_EMAIL,
  EMAIL_LIST,
} from "../../../../routing/routeContants";
import { SubscriptionPlan } from "../profile/subscriptionPlans";
import { EmailSidebar } from "../../../component/frontend/email/EmailSidebar";
import { getUserSocialDetails } from "../../../../routing/authService";
import { GoogleViewEmail } from "../../../component/frontend/email/google/GoogleViewEmail";
import { MicrosoftViewEmail } from "../../../component/frontend/email/microsoft/MicrosoftViewEmail";
import { getAllOutlookMessages, getOutlookAuthLink, getOutlookAuthToken, getOutlookMessageById, getOutlookMessageByIdAttachments, resetMicrosoftState, updateOutlookMessage } from "../../../../duck/email/microsoft/microsoft.action";
import { resetAllState, updateAccordionLabel, updateGoogleLabel, updateMicrosoftLabel } from "../../../../duck/email/email.action";
import { User } from "../../../../storage";

export const ViewEmailPage = (props) => {
  let emailId;
  if (
    props.match &&
    _.has(props.match, "params") &&
    _.has(props.match.params, "id")
  ) {
    emailId = props.match.params.id;
  }
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const {
    loading,
    getGmailMessageByIdData,
    getGmailMessageAttachmentData,
    errorTokenData,
    getGmailTokenData,
  } = useSelector((state) => state.google);
  const {
    loading: msalLoading,
    getOutlookMessageData,
    getOutlookAuthTokenData,
    getAllOutlookMailFoldersData,
    microsoftErrorData,
    getOutlookMessageAttachmentsData,
    updatedMessageData
  } = useSelector((state) => state.microsoft);
  const {
    googleLabel,
    microsoftLabel,
    accordionLabel
  } = useSelector((state) => state.email);
  const userSocialData = getUserSocialDetails();
  const currentPlan = userData && userData.planData;
  const getGmailAuthLinkData = useSelector((state) => state.google.getGmailAuthLinkData);
  const prevGetGmailAuthLinkData = usePrevious({ getGmailAuthLinkData });
  const getOutlookAuthLinkData = useSelector((state) => state.microsoft.getOutlookAuthLinkData);
  const prevGetOutlookAuthLinkData = usePrevious({ getOutlookAuthLinkData });
  const [loader, setLoader] = useState(false);
  const [googleAttachmentData, setGoogleAttachmentData] = useState([]);
  const [outlookAttachmentData, setOutlookAttachmentData] = useState([]);
  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [createLeadState, setCreateLeadState] = useState({ firstName: "", leadName: '', leadDesc: "", email: '' });
  const [uniqueViewKey, setUniqueViewKey] = useState("");

  useEffect(() => {
    if (props.history.location && props.history.location.state && props.history.location.state.uniqueViewKey) {
      setUniqueViewKey(props.history.location.state.uniqueViewKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Create Function
  // const createFunction = (e, path) => {
  //   e.preventDefault();
  //   // Add Free trial expire then working is blocked
  //   //if(currentPlan && currentPlan.plan_is_active === 0){
  //   // Free trial expire then working is fine
  //   if (
  //     currentPlan &&
  //     currentPlan.plan_is_active === 0 &&
  //     (path === "/user/add-quote" || path === "/user/add-invoice/")
  //   ) {
  //     let buttonMsg =
  //       currentPlan.subscription_product_id === 1 ? "View Plans" : "Renew Plan";
  //     let warMsg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Free Trial Expired"
  //         : "Subscription Expired";
  //     let msg =
  //       currentPlan.subscription_product_id === 1
  //         ? "Your free trial has expired. Please subscribe to a plan to access the application. "
  //         : "Your subscription has expired. Please renew your subscription or upgrade your plan to access the application. ";
  //     Swal.fire({
  //       title: warMsg,
  //       html: msg,
  //       showCancelButton: true,
  //       confirmButtonText: buttonMsg,
  //       cancelButtonText: "Close",
  //       reverseButtons: true,
  //       showCloseButton: true,
  //       customClass: "mycustom-alert",
  //       cancelButtonClass: "cancel-alert-note",
  //     }).then((result) => {
  //       if (result.value) {
  //         setSubscriptionModalShow(true);
  //       } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       }
  //     });
  //   } else {
  //     props.history.push(path);
  //   }
  // };

  // useEffect(() => {
  // dispatch(updateGoogleLabel(""));
  // }, [dispatch]);

  useEffect(() => {
    if (uniqueViewKey === "google" && accordionLabel === "gmail")
      dispatch(getGmailMessageById(emailId));
    else if (uniqueViewKey === "microsoft" && accordionLabel === "microsoft")
      dispatch(getOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: emailId }));
  }, [dispatch, emailId, uniqueViewKey, microsoftLabel, accordionLabel]);

  useEffect(() => {
    if (uniqueViewKey === "microsoft" && accordionLabel === "microsoft" && getOutlookMessageData && getOutlookMessageData?.hasAttachments) {
      dispatch(getOutlookMessageByIdAttachments({ mailFolderId: microsoftLabel.id, messageId: emailId }));
    }
  }, [dispatch, emailId, uniqueViewKey, microsoftLabel, getOutlookMessageData, accordionLabel])

  useEffect(() => {
    if (accordionLabel === "microsoft" && getOutlookMessageAttachmentsData && getOutlookMessageAttachmentsData?.value && getOutlookMessageAttachmentsData?.value?.length) {
      setOutlookAttachmentData(getOutlookMessageAttachmentsData?.value);
    }
  }, [getOutlookMessageAttachmentsData, accordionLabel])

  useEffect(() => {
    if (
      accordionLabel === "gmail" &&
      getGmailMessageByIdData &&
      getGmailMessageByIdData?.messages &&
      getGmailMessageByIdData?.messages.length > 0
    ) {
      let attachmentIds = [];
      _.map(
        getGmailMessageByIdData?.messages,
        (msg) =>
          attachmentIds = msg.payload &&
          msg.payload?.parts?.length &&
          msg.payload?.parts.filter((data) => _.has(data.body, "attachmentId"))
      );
      attachmentIds = attachmentIds && attachmentIds?.length > 0 && attachmentIds.filter((obj) => obj !== undefined);
      // eslint-disable-next-line array-callback-return
      attachmentIds && attachmentIds?.length > 0 && attachmentIds.map((attachment) => {
        if (attachment?.body?.attachmentId) {
          dispatch(
            getGmailMessageAttachmentById({
              messageId: emailId,
              attachmentId: attachment.body.attachmentId,
            })
          );
        }
      });
      setGoogleAttachmentData(attachmentIds);
    } else {
      setGoogleAttachmentData([]);
    }
  }, [dispatch, emailId, getGmailMessageByIdData, accordionLabel]);

  useEffect(() => {
    if (
      accordionLabel === "gmail" &&
      getGmailMessageByIdData &&
      getGmailMessageByIdData?.messages?.length > 0 &&
      emailId
    ) {
      _.map(getGmailMessageByIdData?.messages, (data) => {
        if (
          data &&
          data.labelIds &&
          data.labelIds.length > 0 &&
          data?.labelIds.includes("UNREAD")
        ) {
          dispatch(
            markGmailMessageAsUnread({
              messageId: data.id,
              addLabelIds: [],
              removeLabelIds: ["UNREAD"],
            })
          )
        }
      });
    }
  }, [dispatch, emailId, getGmailMessageByIdData, accordionLabel]);

  useEffect(() => {
    if (emailId && accordionLabel === "microsoft" && getOutlookMessageData && Object.keys(getOutlookMessageData).length && !getOutlookMessageData.isRead) {
      dispatch(updateOutlookMessage({ messageId: emailId, body: { isRead: true } }))
    }
  }, [dispatch, emailId, accordionLabel, getOutlookMessageData])

  const handleDeleteMessage = async () => {
    if (emailId) {
      await dispatch(markGmailMessageAsTrash({
        messageId: emailId,
        addLabelIds: ["TRASH"],
        removeLabelIds: [],
      }));
      props.history.push(EMAIL_LIST)
    }
  };
  const handleMarkMessageAsImp = () => {
    if (emailId) {
      dispatch(
        modifyGmailMessage({
          messageId: emailId,
          addLabelIds: ["IMPORTANT"],
          removeLabelIds: [],
        })
      );
    }
  };
  // Create Contact
  const handleCreateContact = (e) => {
    e.preventDefault();
    if (currentPlan && currentPlan.plan_is_active === 1) {
      props.history.push({
        pathname: ADD_CONTACT,
        state: {
          newContactDataState: {
            firstName: createLeadState.firstName && createLeadState.firstName?.trim(),
            lastName: createLeadState.lastName && createLeadState.lastName?.trim(),
            email: createLeadState.email && createLeadState.email?.trim(),
          },
        },
      });
    }
    else {
      Swal.fire({
        title: 'Upgrade Required',
        html: 'Your current plan doesn’t have access to this feature. Please upgrade your account and try again.',
        showCancelButton: true,
        confirmButtonText: 'View Plans',
        cancelButtonText: 'Close',
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: 'cancel-alert-note',
      }).then((result) => {
        if (result.value) {
          setSubscriptionModalShow(true)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log('cancel')
        }
      })
    }
  };
  // Create Lead
  const handleCreateLead = (e) => {
    e.preventDefault();

    if (currentPlan && currentPlan.plan_is_active === 1) {
      const doc = new DOMParser().parseFromString(createLeadState.leadDesc, "text/html");
      const leadDesc = doc.body.textContent
      props.history.push({
        pathname: ADD_LEAD,
        state: {
          newLeadWithContactDataState: {
            firstName: createLeadState.firstName && createLeadState.firstName?.trim(),
            lastName: createLeadState.lastName && createLeadState.lastName?.trim(),
            name: createLeadState.leadName && createLeadState.leadName?.trim(),
            leadDesc: leadDesc,
            email: createLeadState.email && createLeadState.email?.trim(),
          },
        },
      });
    }
    else {
      Swal.fire({
        title: 'Upgrade Required',
        html: 'Your current plan doesn’t have access to this feature. Please upgrade your account and try again.',
        showCancelButton: true,
        confirmButtonText: 'View Plans',
        cancelButtonText: 'Close',
        reverseButtons: true,
        showCloseButton: true,
        customClass: "mycustom-alert",
        cancelButtonClass: 'cancel-alert-note',
      }).then((result) => {
        if (result.value) {
          setSubscriptionModalShow(true)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // console.log('cancel')
        }
      })
    }
  };
  const handleaAtoB = (input) => {
    if (input) {
      return URL.createObjectURL(new Blob([input], { type: "text/plain" }));
    } else {
      return "";
    }
  };

  const handleGoogleLabels = (label) => {
    if (googleLabel !== label) {
      dispatch(updateGoogleLabel(label));
    }
  };

  useEffect(() => {
    if (prevGetGmailAuthLinkData && prevGetGmailAuthLinkData.getGmailAuthLinkData !== getGmailAuthLinkData) {
      if (getGmailAuthLinkData && _.has(getGmailAuthLinkData, "url") && getGmailAuthLinkData.url !== "") {
        window.location.replace(getGmailAuthLinkData.url);
      }
    }
  }, [prevGetGmailAuthLinkData, getGmailAuthLinkData])

  const handleSiginWithGoogle = () => {
    dispatch(getGmailAuthLink());
  }

  useEffect(() => {
    if (prevGetOutlookAuthLinkData && prevGetOutlookAuthLinkData.getOutlookAuthLinkData !== getOutlookAuthLinkData) {
      if (getOutlookAuthLinkData && _.has(getOutlookAuthLinkData, "url") && getOutlookAuthLinkData.url !== "") {
        window.location.replace(getOutlookAuthLinkData.url);
      }
    }
  }, [prevGetOutlookAuthLinkData, getOutlookAuthLinkData])

  const handleSiginWithMicrosoft = () => {
    dispatch(getOutlookAuthLink());
  }

  const handleMicrosoftLabels = (mailLabelObj) => {
    dispatch(updateMicrosoftLabel(mailLabelObj));
    dispatch(getAllOutlookMessages({ mailFolderId: mailLabelObj.id, nextLink: "", search: "" }));
  }
  const handleAccordionClick = (key) => {
    if (props.match.path !== EMAIL_LIST) {
      props.history.push(EMAIL_LIST);
    }
    dispatch(resetAllState());
    dispatch(updateAccordionLabel(key));
  }

  const handleMarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["STARRED"],
        removeLabelIds: [],
      })
    );
  };

  const handleMarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: ["IMPORTANT"],
        removeLabelIds: [],
      })
    );
  };

  const handleUnmarkStarred = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["STARRED"],
      })
    );
  };

  const handleUnmarkImportant = (id) => {
    dispatch(
      modifyGmailMessage({
        messageId: id,
        addLabelIds: [],
        removeLabelIds: ["IMPORTANT"],
      })
    );
  };

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "google") && Object.keys(userSocialData.google).length > 0
      && errorTokenData && Object.keys(errorTokenData).length > 0
    ) {
      if (errorTokenData.code === 401) {
        const refreshToken = userSocialData?.google?.refresh_token
        dispatch(getGmailValidToken(refreshToken));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, errorTokenData])

  useEffect(() => {
    if (
      userSocialData &&
      _.has(userSocialData, "microsoft") && Object.keys(userSocialData.microsoft).length > 0
      && microsoftErrorData && Object.keys(microsoftErrorData).length > 0
    ) {
      if (microsoftErrorData.code === "InvalidAuthenticationToken") {
        const refreshToken = userSocialData?.microsoft?.refresh_token
        dispatch(getOutlookAuthToken(refreshToken));
        User.setSocialUserDetails({ ...userSocialData, microsoft: { ...userSocialData?.microsoft, social_email: null } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, microsoftErrorData])

  useEffect(() => {
    if (uniqueViewKey === "google" && ((getGmailTokenData && Object.keys(getGmailTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "google") &&
      Object.keys(userSocialData.google).length > 0 &&
      userSocialData.google.access_token))) {
      const updateGmailAuthTokenData = {
        loginType: 1, authToken: getGmailTokenData?.access_token, refreshToken: getGmailTokenData?.refresh_token, expiresIn: getGmailTokenData?.expire_in,
        social_email: getGmailTokenData?.social_email,
        social_user_id: getGmailTokenData?.social_user_id,
        social_user_name: getGmailTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 1, authToken: userSocialData?.google?.access_token, refreshToken: userSocialData?.google?.refresh_token, expiresIn: userSocialData?.google?.expire_in,
        social_email: userSocialData?.google?.social_email,
        social_user_id: userSocialData?.google?.social_user_id,
        social_user_name: userSocialData?.google?.social_user_name,
      }
      const updatedData = getGmailTokenData && Object.keys(getGmailTokenData).length > 0 ? updateGmailAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
      if (errorTokenData && Object.keys(errorTokenData).length === 0 && uniqueViewKey === "google")
        dispatch(getGmailMessageById(emailId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getGmailTokenData, uniqueViewKey, emailId, errorTokenData?.length]);

  useEffect(() => {
    if (uniqueViewKey === "microsoft" && ((getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0) || (userSocialData &&
      _.has(userSocialData, "microsoft") &&
      Object.keys(userSocialData.microsoft).length > 0 &&
      userSocialData.microsoft.access_token))) {
      const updateOutlookAuthTokenData = {
        loginType: 2, authToken: getOutlookAuthTokenData?.access_token, refreshToken: getOutlookAuthTokenData?.refresh_token, expiresIn: getOutlookAuthTokenData?.expire_in,
        social_email: getOutlookAuthTokenData?.social_email,
        social_user_id: getOutlookAuthTokenData?.social_user_id,
        social_user_name: getOutlookAuthTokenData?.social_user_name,
      }
      const updateLocalTokenData = {
        loginType: 2, authToken: userSocialData?.microsoft?.access_token, refreshToken: userSocialData?.microsoft?.refresh_token, expiresIn: userSocialData?.microsoft?.expire_in,
        social_email: userSocialData?.microsoft?.social_email,
        social_user_id: userSocialData?.microsoft?.social_user_id,
        social_user_name: userSocialData?.microsoft?.social_user_name,
      }
      const updatedData = getOutlookAuthTokenData && Object.keys(getOutlookAuthTokenData).length > 0 ? updateOutlookAuthTokenData : updateLocalTokenData;
      dispatch(saveSocialAuthToken(updatedData));
      if (microsoftErrorData && Object.keys(microsoftErrorData).length === 0 && uniqueViewKey === "microsoft")
        dispatch(getOutlookMessageById({ mailFolderId: microsoftLabel.id, messageId: emailId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getOutlookAuthTokenData, uniqueViewKey, emailId, microsoftErrorData?.length, microsoftLabel.id])

  useEffect(() => {
    if (updatedMessageData && Object.keys(updatedMessageData).length > 0) {
      dispatch(resetMicrosoftState())
    }
  }, [dispatch, updatedMessageData])

  return (
    <>
      <Loader loader={loading || msalLoading} />
      <div className="main-site fixed--header fixed-email-header">
        <Header getMainRoute={"email"} />
        <main className="site-body bg-white">
          {/* <EmailHeader createFunction={createFunction} /> */}
          <EmailHeader />
          <section className="middle-section email-template-design">
            <div className="container-fluid">
              <div className="row no-gutters-mbl">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="email-template">
                        <div className="email-head d-flex">
                          <div className="top-btn-left">
                            <Link to={COMPOSE_EMAIL} className="email-btn">
                              <img
                                src={setImagePath(COMPOSE_ICON)}
                                alt="COMPOSE_ICON"
                              />
                              <span className="btn-text">Compose</span>
                            </Link>
                          </div>
                          {((googleLabel === "INBOX") || (Object.keys(microsoftLabel).length && microsoftLabel?.displayName === "Inbox")) ? <div className="create-btns ml-auto">
                            <button
                              className="btn btn-secondary"
                              onClick={(e) => handleCreateContact(e)}
                            >
                              Create Contact
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={handleCreateLead}
                            >
                              Create Lead
                            </button>
                          </div> : <></>}
                        </div>
                        <div className="email-content">
                          <EmailSidebar
                            googleErrorData={errorTokenData}
                            microsoftErrorData={microsoftErrorData}
                            handleSiginWithGoogle={handleSiginWithGoogle}
                            handleSiginWithMicrosoft={handleSiginWithMicrosoft}
                            accordionState={accordionLabel}
                            labelId={googleLabel || "INBOX"}
                            userSocialData={userSocialData}
                            msalLabel={microsoftLabel}
                            labelsList={getAllOutlookMailFoldersData && getAllOutlookMailFoldersData?.value}
                            handleGoogleLabels={handleGoogleLabels}
                            handleMicrosoftLabels={handleMicrosoftLabels}
                            handleAccordionClick={handleAccordionClick}
                          />
                          {uniqueViewKey === "google" ?
                            <GoogleViewEmail
                              uniqueViewKey={uniqueViewKey}
                              getActiveLabel={googleLabel}
                              getGmailMessageByIdData={getGmailMessageByIdData}
                              getGmailMessageAttachmentData={getGmailMessageAttachmentData}
                              attachmentData={googleAttachmentData}
                              createLeadState={createLeadState}
                              handleaAtoB={handleaAtoB}
                              setCreateLeadState={setCreateLeadState}
                              handleUnmarkStarred={handleUnmarkStarred}
                              handleMarkStarred={handleMarkStarred}
                              handleUnmarkImportant={handleUnmarkImportant}
                              handleMarkImportant={handleMarkImportant}
                              handleDeleteMessage={handleDeleteMessage}
                              handleMarkMessageAsImp={handleMarkMessageAsImp}
                            />
                            :
                            <MicrosoftViewEmail
                              userSocialData={userSocialData}
                              uniqueViewKey={uniqueViewKey}
                              msalLoading={msalLoading}
                              getOutlookMessageData={getOutlookMessageData}
                              attachmentData={outlookAttachmentData}
                              createLeadState={createLeadState}
                              handleaAtoB={handleaAtoB}
                              setCreateLeadState={setCreateLeadState}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        {/* Subscription Modal*/}
        <SubscriptionPlan
          loader={(data) => setLoader(data)}
          openSubscriptionModal={subscriptionModalShow}
          closeSubscriptionModal={() => { setSubscriptionModalShow(false); }}
          updatePlanDetail={(data) => {
            setSubscriptionModalShow(false);
            setLoader(false);
          }}
          currentPlan={currentPlan}
        />
      </div>
    </>
  );
};

export const ViewEmail = withRouter(ViewEmailPage);
