import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ERROR_ICON from '../../../../assets/images/error-icn.svg'
import Modal from "react-bootstrap/Modal";
import { constants, selectStyle } from "../../../../common/constants";
import { usePrevious, setImagePath } from '../../../../common/custom';
import moment from 'moment'
import _ from 'lodash';
import { getStateTax, createSubscritpionPlan, getSubscritpionPlan, updateSubscritpionPlan, planApplyCoupon ,getAutoReplyData} from '../../../../duck/profile/profile.action';
import { validateInputs } from '../../../../common/validation';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getUserDetails, setUserDetails } from '../../../../storage/user';
import { getMenuCount,deleteAllDummyData ,updateAutoReplyData } from "../../../../duck/profile/profile.action";
import Swal from 'sweetalert2';
import { Editor } from '@tinymce/tinymce-react';
import { tinyConfigEmailContent } from "../../../../common/constants";
import { tinyConfigAutoReplyEmailContent } from "../../../../common/constants";
import {
  successNotification,
} from '../../../../common/notification-alert';

export const AutoReplyModel = props =>{

    const defaultMsg = 'Hello,<br/><p>Thank you for your contacting us! We will get back to you as soon as possible.</p>Thanks,<br/> Business Owner Name <br/>Busniess Name <br/>ETC.'
    
    const {show ,message ,subject , autoReplyStatus }  = props
    const [errorMessage,seterrorMessage]  = useState('')
    const [error,setError]  = useState({})
    const [editorData, setEditorData] = useState(defaultMsg);
    const [subjectData, setSubject] = useState('');
    const [userAutoReplyStatus,setUserAutoReplyStatus] = useState(props.userAutoReplyStatus)
    const autoReplydata = useSelector(state => state.profile.auto_reply_data);
    const prevAutoReplydata = usePrevious({ autoReplydata });


    const dispatch = useDispatch();

    useEffect(()=>{  
      dispatch(getAutoReplyData())
      setSubject(props.subject)
      setEditorData(props.message)
    },[]);  

    useEffect(()=>{ 
        setSubject(props.subject)
        setEditorData(props.message)
        setUserAutoReplyStatus(props.autoReplyStatus)
    },[show]);

    const handleOnChange = () => {
      setUserAutoReplyStatus(!userAutoReplyStatus);
    };

    const saveMessage = ()=>{
      let errStatus=false;
      const errFrm={}

      setError({})
      console.log(subjectData)
      if(subjectData.trim()===''){
        errFrm.subject =  'Please provide the subject';
        errStatus=true;
      }
      if(editorData===''){
        errFrm.content =  'Please provide the content';
        errStatus=true;
      }

      if(errStatus){
        setError(errFrm)
        return false
      }

      setError({})
      props.setLoader(true)
      let data = {subject:subjectData, content:editorData, userAutoReplyStatus: userAutoReplyStatus}  
      dispatch(updateAutoReplyData(data));

    } 

    const handleClose=()=>{
        //seterrorMessage('')
        //setdeleteDummyDataButtonReason(false)
        setError({})
        props.onHide()
    }

    useEffect(()=>{
    },[error])

    useEffect(()=>{

      if (prevAutoReplydata && prevAutoReplydata.autoReplydata !== autoReplydata) {
        if (autoReplydata && _.has(autoReplydata, 'data') && autoReplydata.success === true) { // alert()
            setTimeout(() => {
              props.setLoader(false)
              props.onHide()
              //successNotification(autoReplydata.message)
            }, 1000); 
            
        }
        if (autoReplydata && _.has(autoReplydata, 'message') && autoReplydata.success === false) {
            props.setLoader(false)
          
        }
    }      

    },[prevAutoReplydata, autoReplydata])

    return (
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          className="auto-reply-model"
          centered
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">                           
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>    
          <h3>Automatic Reply to Customer</h3>     
          <p className="auto-reply-content">Your customers will receive this automatic reply, if enabled, when they submit a request on your website. The auto-reply will work <strong>ONLY</strong> if you have also enabled automtaic lead creation. </p>             
          <div className="subject-text">
            <span className="auto-reply-subject" >Subject : </span>
            <input type="text" value={subjectData} onChange={(e)=>{setSubject(e.target.value);}} className="form-control" name="subject"></input>
          </div>
          {error && error.subject 
            ? <p className="error" style={{ marginLeft: "65px", padding: "2px"}}>{error.subject}</p> : ''
          }
          <br/>
          <div className="editorbox">
          <Editor
              value={props.message !== '' ? props.message : defaultMsg}
              apiKey={constants.tinyAapiKey}
              init={tinyConfigAutoReplyEmailContent}
              onEditorChange={(data) => setEditorData(data)}
          />
          </div>
          {error && error.content 
            ? <p className="error">{error.content}</p> : ''
          }          
          <span className="enable-checkbox"><input type="checkbox" checked={userAutoReplyStatus} onChange={()=>{handleOnChange()}} ></input>Enable auto-reply</span>
          </Modal.Body>
          <Modal.Footer>
          {errorMessage && (
            <p className="error"> {errorMessage} </p>
            )}
          <button  className="btn btn-dark " onClick={()=>{handleClose()}}>Cancel</button>
          <button  className="btn btn-primary " onClick={()=>{saveMessage()}}>Save</button>
          </Modal.Footer>
        </Modal>
      );


}    